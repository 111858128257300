import axios from "axios";
import CONSTANT from "../utils/constant";

const API_URL = CONSTANT.API_URL
const API_URL_DATA = CONSTANT.API_URL_DATA

const SETUP = CONSTANT.SETUP
const token = localStorage.getItem("token");
const API_METADATA_URL = CONSTANT.API_METADATA_URL

const API_DATA_URL = `${API_URL_DATA}`;

const fetchMetadata = async (moduleName) => {
  const metadataKey = `${moduleName}_metadata`;
  const cachedMetadata = localStorage.getItem(metadataKey);

  if (cachedMetadata != null && cachedMetadata.length !== 0) {
    return JSON.parse(cachedMetadata);
  } else {
    try {
      const response = await axios.post(API_METADATA_URL, {
        setup: SETUP,
        modulename: moduleName,
        compte: localStorage.getItem("compte"),
      });
      if (response.data && response.data.success) {
        localStorage.setItem(metadataKey, JSON.stringify(response.data.data));
        return response.data.data;
      } else {
        console.error("Error fetching metadata:");
        // throw new Error("Failed to fetch metadata");
      }
    } catch (error) {
      console.error("Error fetching metadata:", error);
      return null;
    }
  }
};

const reloadData = async () => {
  var initPage = localStorage.getItem("initLoad");
  var start = new Date();
  if (initPage == null || initPage.length === 0) {
    localStorage.setItem("initLoad", start);
    let timeout = setInterval(function () {
      reloadData();
    }, 1000);
    localStorage.setItem("__timeout", timeout);
  } else {
    var restore = new Date(initPage);
    if (restore.getMinutes() + 2 < start.getMinutes()) {
      localStorage.setItem("initLoad", start);
      let moduleName = "HelpDesk";
      let dataKey = `${moduleName}_data`;
      await fetchDataApi(moduleName, dataKey);
      moduleName = "Factures";
      dataKey = `${moduleName}_data`;
      await fetchDataApi(moduleName, dataKey);
      moduleName = "Documents";
      dataKey = `${moduleName}_data`;
      await fetchDataApi(moduleName, dataKey);
      moduleName = "Approvisionnements";
      dataKey = `${moduleName}_data`;
      await fetchDataApi(moduleName, dataKey);
    }
  }
};

const fetchData = async (moduleName, id = null) => {
  const dataKey = `${moduleName}_data`;
  try {
    let data = localStorage.getItem(dataKey);
    if (data !== null && data.length !== 0) {
      reloadData();
      return JSON.parse(data);
    } else {
      let data = await fetchDataApi(moduleName, dataKey);
      reloadData();
      return data;
    }
  } catch (error) {
    // console.error("Error fetching data:", error);
    return null;
  }
};

const fetchDataApi = async (moduleName, dataKey) => {
  const response = await axios.post(`${API_DATA_URL}${moduleName}/getAll`, {
    setup: SETUP,
    moduleName: moduleName,
    // compte: CONSTANT.COMPTE_USER
    compte: localStorage.getItem("compte"),
  });
  if (response.data && response.data.success) {
    localStorage.setItem(dataKey, JSON.stringify(response.data));
    return response.data;
  } else {
    throw new Error("Failed to fetch data");
  }
};

const fetchDataCount = async (moduleName) => {
  const data = await fetchData(moduleName);
  return data.records.length;
};

const storeDataInLocalStorage = (moduleName, data) => {
  localStorage.setItem(moduleName, JSON.stringify(data));
};

const getDataFromLocalStorage = (moduleName) => {
  const data = localStorage.getItem(moduleName);
  return data ? JSON.parse(data) : null;
};

const refreshMetadata = async (moduleName) => {
  const data = await fetchMetadata(moduleName);
  if (data) {
    storeDataInLocalStorage(moduleName, data);
  }
};

const clearLocalStorage = (moduleName) => {
  localStorage.removeItem(moduleName);
};

const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
};

const getDataSelectedInput = () => {
  let endpoints = [
    API_URL + "enum/Priorité/" + SETUP,
    API_URL + "enum/Statut/" + SETUP,
    API_URL + "enum/Etat/" + SETUP,
    API_URL + "groups/" + SETUP,
  ];
  return axios.all(endpoints.map((endpoint) => axios.get(endpoint, headers)));
};

const getCreateUrl = (pathname) => {
  let createUrl;
  switch (true) {
    case pathname.includes("requetes"):
      createUrl = "/requetes/create";
      break;
    case pathname.includes("factures"):
      createUrl = "/factures/create";
      break;
    case pathname.includes("documents"):
      createUrl = "/documents/create";
      break;
    case pathname.includes("approvisionnement"):
      createUrl = "/approvisionnements/create";
      break;
    default:
      createUrl = "";
  }
  return createUrl;
};

const getHeaderTitle = (pathname) => {
  let title;
  switch (true) {
    case pathname.includes("requetes"):
      title = "Requête";
      break;
    case pathname.includes("factures"):
      title = "Facture";
      break;
    case pathname.includes("documents"):
      title = "Document";
      break;
    case pathname.includes("approvisionnements"):
      title = "Approvisionnement";
      break;
    default:
      title = "";
  }
  return title;
};

const getModuleNameByPathname = (pathname) => {
  let moduleName;
  switch (true) {
    case pathname.includes("requetes"):
      moduleName = "HelpDesk";
      break;
    case pathname.includes("factures"):
      moduleName = "Factures";
      break;
    case pathname.includes("documents"):
      moduleName = "Documents";
      break;
    case pathname.includes("approvisionnement"):
      moduleName = "Approvisionnements";
      break;
    default:
      moduleName = null;
  }
  return moduleName;
};

const detailTitle = (pathname) => {
  switch (true) {
    case pathname.includes("requetes"):
      return "Titre";
    case pathname.includes("factures"):
      return "Nom facture";
    case pathname.includes("documents"):
      return "Titre";
    case pathname.includes("approvisionnement"):
      return "Nom Approvisionnement";
    default:
      return "";
  }
};

const getUpdateUrl = (pathname, id) => {
  const moduleName = pathname.split("/")[1];
  return `/${moduleName}/update/${id}`;
};

const getFieldType = (pathname, field) => {
  const fields = getUpdateViewFields(pathname);
  const fieldData = fields.find((f) => f.displayName === field);
  return fieldData ? fieldData.type : null;
};

const getFieldOptions = (pathname, field) => {
  const fields = getUpdateViewFields(pathname);
  const fieldData = fields.find((f) => f.displayName === field);
  return fieldData ? fieldData.options : [];
};

const isFieldRequired = (pathname, field) => {
  const fields = getCreateViewFields(pathname);
  const fieldData = fields.find((f) => f.displayName === field);
  return fieldData ? fieldData.mandatory : false;
};

const getListeViewFields = (pathname) => {
  const moduleName = getModuleNameByPathname(pathname);
  const jsonData = getDataFromLocalStorage(moduleName + "_metadata");
  if (jsonData && jsonData.operations && jsonData.operations.ListeView) {
    return jsonData.operations.ListeView.fields;
  }
  return [];
};

const getDetailViewFields = (pathname) => {
  const moduleName = getModuleNameByPathname(pathname);
  const jsonData = getDataFromLocalStorage(moduleName + "_metadata");
  return jsonData?.operations?.DetailView?.fields || [];
};

const getUpdateViewFields = (pathname) => {
  const moduleName = getModuleNameByPathname(pathname);
  const jsonData = getDataFromLocalStorage("Meta_" + moduleName);

  return jsonData?.operations?.UpdateView?.fields || [];
};

const getCreateViewFields = (pathname) => {
  const moduleName = getModuleNameByPathname(pathname);
  const jsonData = getDataFromLocalStorage("Meta_" + moduleName);

  return jsonData?.operations?.CreateView?.fields || [];
};

const getMetadataByPathname = (pathname) => {
  let moduleName;
  switch (true) {
    case pathname.includes("requetes"):
      moduleName = "HelpDesk";
      break;
    case pathname.includes("factures"):
      moduleName = "Factures";
      break;
    case pathname.includes("documents"):
      moduleName = "Documents";
      break;
    case pathname.includes("approvisionnement"):
      moduleName = "Approvisionnements";
      break;
    default:
      moduleName = null;
  }
  return moduleName;
};

const getCount = async (moduleName) => {
  const jsonData = await fetchMetadata(moduleName);
  if (jsonData && jsonData.data) {
    return jsonData.data.length;
  }
  return 0;
};

const getMetadata = async (moduleName) => {
  let jsonData = getDataFromLocalStorage("Meta_" + moduleName);
  if (!jsonData) {
    jsonData = await fetchMetadata(moduleName);
    if (jsonData) {
      storeDataInLocalStorage("Meta_" + moduleName, jsonData);
    }
  }
  return jsonData;
};

const getData = async (moduleName, id) => {
  const jsonData = await fetchData(moduleName, id);
  return jsonData;
};

const invalidateLocalStorage = (moduleName) => {
  localStorage.removeItem(moduleName);
};

const Services = {
  getDataSelectedInput,
  getHeaderTitle,
  getCreateUrl,
  detailTitle,
  getModuleNameByPathname,
  getUpdateUrl,
  getFieldOptions,
  getFieldType,
  isFieldRequired,
  fetchMetadata,
  fetchData,
  fetchDataCount,
  storeDataInLocalStorage,
  getDataFromLocalStorage,
  refreshMetadata,
  clearLocalStorage,
  getMetadataByPathname,
  getListeViewFields,
  getDetailViewFields,
  getUpdateViewFields,
  getCreateViewFields,
  getCount,
  getMetadata,
  getData,
  invalidateLocalStorage,
  SETUP,
  API_URL,
};

export default Services;
