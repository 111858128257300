import React, { useState } from "react";
import "../assets/css/login.css";

import Eye from "../assets/icons/solar_eye-outline.svg";
import Logo from "../assets/icons/logo.png";
import logoCeteris from "../assets/icons/Ceteris.jpg";
import { useNavigate } from "react-router-dom";
import ModalResetPassword from "../components/resetPassword";
import AuthService from "../services/auth.service";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    AuthService.login(email, password).then(
      (Response) => {
        setLoading(false);
        // let profil = Response?.data?.profil;
        // let role = Response?.data?.role;
        let id = Response?.data?.id;
        const expired = new Date();
        console.log(expired.getMinutes() + 30);
        expired.setMinutes(expired.getMinutes() + 30);
        // localStorage.setItem("__profil", profil);
        // localStorage.setItem("__role", role);
        localStorage.setItem("__id", id);
        localStorage.setItem("__expired_myToken", expired);
        //localStorage.getItem('role') === "ROLE_ADMIN" ? navigate('/admin/animateurs') : navigate('/dashboard/events');
        navigate("/dashboard");
      },
      (error) => {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          console.log("password not valide", error.response.data.message);
          setError(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 404) {
          console.log("Email incorrect");
          setError("user not found");
          return;
        }
        console.log("err", error);
        // Réactiver le bouton de connexion si l'erreur est due à un mot de passe incorrect
        if (error.response && error.response.status === 401) {
          // Réinitialiser l'erreur
          setError("Mot de passe ou email incorrecte");
        }
      }
    );
  };
  const updateIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-fluid login">
      {isOpen ? (
        <ModalResetPassword isOpen={isOpen} updateIsOpen={updateIsOpen} />
      ) : null}

      <div className=" row">
        <div className="col-md-12 col-lg-4 d-flex align-self-center justify-content-center left">
          <img className="login-logo" src={logoCeteris} alt="logo" />
        </div>
        <div className="col-md-12 col-lg-8 right">
          <div className="form-login ">
            <h1 className="title-login">Se connecter</h1>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="username" className="label-login">
                  E-mail
                </label>
              </div>
              <div>
                <input
                  tabIndex={1}
                  className="form-control"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setError("");
                  }}
                  placeholder="Adresse e-mail"
                />
              </div>
              <div className="password-field mt-4">
                <label htmlFor="password">Mot de passe</label>
              </div>
              <div className="password-input-container">
                <input
                  tabIndex={1}
                  className="form-control password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setError("");
                  }}
                  placeholder="Mot de passe"
                />
                {showPassword ? (
                  <img
                    src={Eye}
                    className="password-icon"
                    alt="password show"
                    onClick={handleToggle}
                  />
                ) : (
                  <img
                    src={Eye}
                    className="password-icon"
                    alt="password-hide"
                    onClick={handleToggle}
                  />
                )}
              </div>

              <div className="float-right pt-10">
                <a
                  tabIndex={4}
                  onClick={() => {
                    updateIsOpen();
                  }}
                  className="cta mt-24"
                >
                  Mot de passe oublié
                </a>
              </div>
              <button
                disabled={loading}
                type="submit"
                className="btn-primary-al w-100 mt-4"
                tabIndex={3}
                autoFocus
              >
                Se connecter &nbsp;
                {loading ? <i className="fa fa-spinner"></i> : ""}
              </button>
              <span className="text-danger pt-5">{error}</span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
