const SETUP = "ctci";
const HELPDESK = "HelpDesk";
const USERS = "Users";
const COMPTE = "ctcitest";
const FACTURES = "Factures";
const APPRO = "Approvisionnements";
const ETAT_A_TRANSMETTRE = "A transmettre";
const PRIORITY_ELEVE = "Elevée";
const PRIORITY_MOYENNE = "Moyenne";
const PRIORITY_FAIBLE = "Faible";
const PROFIL_AGENTCICG = "2";
const PROFIL_RESPOCICG = "6";
const PROFIL_PRIMATURE = "8";
const PROFIL_PORTE_PAROLAT = "5";
const PROFIL_MINISTRE = "9";
const PROFIL_DIR_CAB = "4";
const PROFIL_POINTFOCAL = "3";
const API_URL = "https://ctci.estair.travel/estair_v310.1/public/api/";
const API_URL_DATA = "https://ctci.estair.travel/estair_v310.1/public/api/";
const API_METADATA_URL =
  "https://ctci.estair.travel/estair_v310.1/public/api/module/getmetadata";
// const COMPTE_USER = "11x9336"

const Constant = {
  SETUP,
  HELPDESK,
  USERS,
  FACTURES,
  PROFIL_AGENTCICG,
  PROFIL_RESPOCICG,
  PROFIL_PRIMATURE,
  PROFIL_PORTE_PAROLAT,
  PROFIL_MINISTRE,
  PROFIL_DIR_CAB,
  PROFIL_POINTFOCAL,
  ETAT_A_TRANSMETTRE,
  PRIORITY_ELEVE,
  PRIORITY_MOYENNE,
  PRIORITY_FAIBLE,
  COMPTE,
  APPRO,
  API_URL,
  API_URL_DATA,
  API_METADATA_URL,
};
export default Constant;
